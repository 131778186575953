<template>
  <div style="background-color: #e5e5e5;">
    <ResetTop/>
    <div class="re_step">
      <div :class="[active === 0 ? 're_step_item_active' :'']" class="re_step_item">1.帐号验证</div>
      <div :class="[active === 1 ? 're_step_item_active' :'']" class="re_step_item">2.完成</div>
    </div>
    <div class="re_main">
       <div v-if="active === 0" class="re_one">
         <el-form :model="resetForm" :rules="resetRules" ref="resetForm">
           <el-form-item prop="telephoneNumber">
             <el-input v-model="resetForm.telephoneNumber"  placeholder="请输入手机号"></el-input>
           </el-form-item>
           <el-form-item prop="smsCode">
             <div class="specil">
               <el-input type="text" v-model="resetForm.smsCode" placeholder="请输入验证码"></el-input>
               <div :class="[btnFlag ? 'false' :'sended']" :disabled="btnFlag" @click="sendPhoneCode">{{ result > 0 ? `${result}s` : "获取验证码"}}</div>
             </div>

           </el-form-item>
           <el-form-item prop="password">
             <el-input  type="password" v-model="resetForm.password"  placeholder="请输入密码"></el-input>
           </el-form-item>
           <el-form-item prop="comfirePassword">
             <el-input type="password" v-model="resetForm.comfirePassword" placeholder="再次输入密码"></el-input>
           </el-form-item>
         </el-form>
         <Verify @success="goReset"
                 :mode="'pop'"
                 :captchaType="'blockPuzzle'"
                 :imgSize="{ width: '330px', height: '155px' }"
                 ref="verify">
         </Verify>
         <el-button type="primary" size="medium" class="one_btn" @click="goVerify">下一步</el-button>
       </div>
      <div v-else-if="active === 1" class="re_three">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="48" height="48" viewBox="0 0 48 48">
          <image id="矢量智能对象" width="48" height="48" xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAADbElEQVRogeWaPWgUQRiGn6wYjNiE0SLoIiwoFjaKoFEiEo0REVLZSKzsxDpoIIikEFMH7CJIjI1N0CYXsAn+QWxEISAsyCIiuqQRg4pG5vz23Nvbndu927276Nvd3s3M8978fd/MdpGTfNvpBvoABfQCPUC31P4dWANW9U+BD8pz9bOm1ZQB33ZsYBQYAQ4Bm1IW/QksA/PArPJcr1GGhgz4tnMUmACGm/0TgHVgAZhUnvs0a+FMjfu2sweYBk5nbSilSsAV5blvczXg244FjAE3QuO6KOm5cR2YUp77q2kDvu1sB+aAoYLBo1oELijP/Wz6kdGATFLdrfuKJDVoRQ9X0yRPNCDwz4CdLYaO6j3Qn2TCinsow6bUAfAIQ0mYalRjQCbsXBuHTZw0y5ywVSmuB8baMGHTaEjYqlQ1B2Sdf92CpbJR6SV2f3ifiPbAdAfDI2zT4QeVHpDw4ElbsLLrWBB2hHtgogPAZoCzwEXgleF3FdZyD8ia/y6HwKwZXVaeezso79vONuAlsDemTh0A7tZ7Q9ADo50Er6U894v0SJy6hLkyhEZaTRxSDXxIPwzlysxdkkl9zZCM5KlEeOHSoczBhPZ0UrTVkjSw0+A1zx0DPMLcZ0kO22rVg7+rQ+kUTMqSBDyN3gBLwLcmzeYFr9VryemBSXqsnVeeq7fw40AQbrQbXqvHShE6zCjPfRB8kLh8sAETecNrdcfmAxEtRx8oz/2U0UQR8GVZEuGZdCDuuwwmCoPX7JacmJl0ybedkw2aKBJea82S4z6TNgMPfdsZzGiiaHitVUvOKutJr1SPMphoBXy5uqyhhB5u55TnPk6A2wGcUp57P+H7POHLoUQQTj8HDqcsaDSRpJzhtV4ozz0SLKPzGQoah1OcCoAnYA4MzEqSkFapTRQEvy7MfwzI7rqQsZK6JgqC11oITurCO/FkAxUlmigQnjBrxYBk+aUGKgtMDAcPfNvZAtwrCL4UvgjJ+2BLH4l/BE4Au5pGrVXNwVZNIu/bzlXgZgGN56Fx5blVbHHR6JT8k50mzXQryhR7lCJH2UsddEKtLzoG4m5r/s0LDv7uDf3ivl1aMcEbDYRMDLRpTizKsDFegtdNKWXcnQGupcje8pBuY1y3We+Gkv/mojuqDfuqQVQb9mWPOLXldRvgN1AqZYwfNJOGAAAAAElFTkSuQmCC"/>
        </svg>
        <div>
          <el-button type="primary" size="medium" class="one_btn" @click="$router.push('/home')">返回首页</el-button>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
  import _ from 'lodash'
  import {Encrypt} from '@/utils/secret.js'
  import ResetTop from "@/components/reset/ResetTop"
  import Footer from "@/components/Footer"
  import { reqTelCode, resetPassword } from '@/utils/network'
  import Verify from "@/components/verifition/Verify"
  export default {
    name: 'PageOne',
    data() {
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.resetForm.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      var checkPhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('手机号不能为空'));
        } else {
          const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/
          //console.log(reg.test(value));
          if (reg.test(value)) {
            callback();
          } else {
            return callback(new Error('请输入正确的手机号'));
          }
        }
      };
      return {
        btnFlag: false,
        timer: null,   //定时器
        result: null,   //此变量为截止时间戳与当前时间戳相减的差
        timeStamp: null,//此变量为倒计时截止的时间
        active:0,
        showCodeBtn:true,
        btntext:'接受验证码',
        resetForm:{
          telephoneNumber:'',
          smsCode:'',
          password:'',
          comfirePassword:''
        },
        resetRules:{
          password:[
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 8, max: 20, message: '长度在 8 到 20 个字符', trigger: 'blur' }
          ],
          comfirePassword:[
            { validator: validatePass2, trigger: 'blur' }
          ],
          telephoneNumber:[

            { validator: checkPhone, trigger: 'blur' }
          ],
          smsCode:[{ required: true, message: '请输入手机验证码', trigger: 'blur' },],
        }
      }
    },
    methods: {
      goVerify(){
        this.$refs.resetForm.validate(async flag => {
          if (flag) {
            this.$refs.verify.show()
          } else {
            this.$message.error('请填写完整表单')
          }
        })
      },
      goReset() {
        this.$refs.resetForm.validate(async flag => {
          if(flag) {
            const obj = {}
            _.defaultsDeep(obj, this.resetForm)
            obj.password = Encrypt(obj.password)
            obj.comfirePassword = Encrypt(obj.comfirePassword)
            const { data: res }  = await  resetPassword(obj)
            if (res.code !== 200) return this.$message.error(`${res.message}`)
            this.active = 1
            this.$refs.resetForm.resetFields()
          }else {
            this.$message.error('请填写完整表单')
          }
        })

      },
      async sendPhoneCode() {
        /* 发送手机验证码 */
        if (this.resetForm.telephoneNumber.trim() !== "") {
          /* 验证邮箱是正确 */
          var reg = /^1[3|4|5|7|8|9]\d{9}$/;
          if (reg.test(this.resetForm.telephoneNumber) === true) {

            const { data: res } = await reqTelCode({
              telephoneNumber:this.resetForm.telephoneNumber
            })
            if(res.code !== 200) return this.$message.error(`${res.message}`)
            this.countTime()
            // alert(`${res.data}`)
            // console.log(res)

          } else {
            this.$message.error("输入正确的手机号！");
          }
        } else {
          this.$message.error("手机号不能空着呢！");
        }
      },
      initTimer() {
        window.addEventListener('beforeunload',()=>{
          if (this.timeStamp) {
            localStorage.setItem("reduceTimeStamp", JSON.stringify(this.timeStamp));
            clearInterval(this.timer);
          }
        })
        let temp = JSON.parse(localStorage.getItem("reduceTimeStamp"));
        let NowStamp = new Date().getTime();
        //如果<=0说明倒计时已结束，将按钮恢复原始状态
        if (+temp - NowStamp <= 0) {
          this.result = null;
          this.btnFlag = false;
        } else {
          this.result = parseInt((temp - NowStamp) / 1000);
        }
      },
      countTime() {
        //如果result<=0,证明上一次读秒已结束，需要重新设置新的时间戳
        this.btnFlag = !this.btnFlag;
        if (!this.result) {
          let currentStamp = new Date().getTime();
          this.timeStamp = new Date().getTime() + 60 * 1000;
          //我设置了30秒
          this.result = Math.floor((this.timeStamp - currentStamp) / 1000);
        }
        this.timer = setInterval(() => {
          this.result--;
          if (this.result === 0) {
            clearInterval(this.timer);
            this.timer = null;
            this.btnFlag = false;
          }
        }, 1000);
      },
    },
    components: {
      ResetTop,
      Footer,Verify
    }
  }
</script>

<style lang="less" scoped>
  .re_step {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    width: 65%;
    margin: 20px auto 20px;
    height: 30px;
    .re_step_item_active {
      background-color: red;
      color: #fff !important;
    }

    .re_step_item {
      font-size: 14px;
      //cursor: pointer;
      color: red;
      border-radius: 180px;
      height: 30px;
      width: 150px;
      line-height: 30px;
      border: 1px solid red;
    }
  }
  .re_main {
    height: 670px;
    margin: 30px auto;
    width: 65%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .one_btn {
      margin: 20px 60px;
      width: 110px;
    }
    .re_one{
      .specil {
        .false {
          border: none;
          background-color:#ccc;
          height: 30px;
          width: 130px;
          line-height: 30px;
          text-align: center;
          color: #000;
          cursor: auto;
          font-size: 12px;
          border-radius: 180px;
        }
        .sended {
          border: none;
          cursor: pointer;
          background-color:red;
          height: 30px;
          width: 130px;
          line-height: 30px;
          text-align: center;
          color: #fff;
          font-size: 12px;
          border-radius: 180px;
        }
      }

    }
    .re_two {


    }
    .re_three {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .one_btn {
        margin-top: 100px;
      }

    }
  }

</style>
